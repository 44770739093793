import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

export const HiddenCategoryText = styled.p`
  z-index: -1;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
`;

export const LockWrapper = styled.div`
  position: relative;
  border-radius: 4px;
`;
export const LockBackground = styled.div`
  background-color: ${(props) => props.theme.palette.primary.textSecondary};
  position: absolute;
  border-radius: 0 0 0 0 4px;
  clip-path: polygon(0 0, 100% 0, 0 100%);
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: 20;
  width: 65px;
  height: 65px;
`;

export const useStyles = makeStyles((theme) => ({
  media: {
    width: '100%',
    height: '200px',
    margin: '0 auto'
  },
  cardStyle: {
    fontSize: '1rem',
    width: '100%',
    boxShadow: '0 4px 15px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.2)',
    flex: '0 0 calc(100% - 20px)',
    margin: '10px',
    [theme.breakpoints.up('sm')]: {
      flex: '0 0 calc(50% - 20px)'
    },
    [theme.breakpoints.up('md')]: {
      flex: '0 0 calc(33% - 20px)'
    },
    [theme.breakpoints.up('lg')]: {
      flex: '0 0 calc(25% - 20px)'
    }
  },
  hoverCardStyle: {
    fontSize: '1rem',
    width: '100%',
    boxShadow: '0 4px 15px 0 rgba(0, 0, 0, 0.6), 0 6px 20px 0 rgba(0, 0, 0, 0.4)',
    flex: '0 0 calc(100% - 20px)',
    margin: '10px',
    [theme.breakpoints.up('sm')]: {
      flex: '0 0 calc(50% - 20px)'
    },
    [theme.breakpoints.up('md')]: {
      flex: '0 0 calc(33% - 20px)'
    },
    [theme.breakpoints.up('lg')]: {
      flex: '0 0 calc(25% - 20px)'
    }
  },
  titleWrapper: {
    background: theme.palette.primary.backgroundSecondary
  },
  cardTitle: {
    fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
    fontWeight: 'bold',
    letterSpacing: '.1rem',
    lineHeight: '1.54',
    textAlign: 'center',
    display: 'block',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    color: theme.palette.primary.backgroundPrimary
  },
  subTitle: {
    display: 'block',
    textTransform: 'uppercase',
    fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
    color: theme.palette.primary.textSecondary,
    fontWeight: '400',
    letterSpacing: '.1rem',
    lineHeight: '1.54',
    textAlign: 'center',
    fontSize: '0.75rem'
  },
  lockIcon: {
    position: 'absolute',
    zIndex: '1',
    margin: '0.25rem',
    color: theme.palette.primary.backgroundSecondary,
    borderRadius: '11px'
  }
}));
