import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    color: theme.palette.primary.textDark,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
    marginTop: '20px'
  },
  deliveryMethod: {
    marginTop: '1rem'
  },
  btnGroup: {
    position: 'absolute',
    bottom: '20px',
    left: '0',
    flexDirection: 'row',
    alignItems: 'center'
  },
  radio: {
    color: theme.palette.primary
  },
  button: {
    background: theme.palette.primary.backgroundSecondary,
    color: theme.palette.primary.backgroundPrimary,

    '&:hover': {
      background: theme.palette.primary.backgroundPrimary,
      color: theme.palette.primary.textSecondary
    }
  }
}));
