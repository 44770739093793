import styled from 'styled-components';
import sizes from 'constants/sizes'; // Import your sizes object

// Styled component for the main container using CSS Grid
export const MetricsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 items in a row by default */
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  text-align: center;
  padding: 2rem 1rem;
  justify-items: center;
  width: 100%;
  /* Adjust layout for medium screens (MD) */
  @media (max-width: ${sizes.MD}px) {
    /* Adjust max-width to your MD size (tablet size) */
    grid-template-columns: repeat(2, 1fr); /* 2 items in a row */
  }

  /* Stack into 1 column on smaller screens (SM) */
  @media (max-width: ${sizes.SM}px) {
    /* Adjust max-width to your SM size (mobile size) */
    grid-template-columns: 1fr; /* Stack into 1 column */
  }
`;

// Styled component for each individual metric box
export const MetricBox = styled.div`
  cursor: pointer;
  background: #f5f5f5;
  padding: 1.5rem 0;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%; // Allows box to grow within the grid cell

  &:hover {
    transform: translateY(-5px);
  }
`;

// Styled component for the number (e.g., "100+")
export const MetricNumber = styled.div`
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 0.5rem; // Space between number and label
`;

// Styled component for the label (e.g., "megmentett fa")
export const MetricLabel = styled.div`
  font-size: 18px;
  color: #555;
`;
