import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(1),
    width: '100%'
  },
  inputLabel: {
    color: theme.palette.primary.backgroundPrimary
  },
  inputControl: {
    color: theme.palette.primary.backgroundPrimary
  },
  selectEmpty: {
    margin: theme.spacing(2)
  },
  actionsContainer: {
    marginTop: theme.spacing(2),
    alignItems: 'center'
  },
  button: {
    color: theme.palette.primary.backgroundPrimary
  },
  nextButton: {
    color: theme.palette.primary.textSecondary,
    background: theme.palette.primary.backgroundPrimary,
    '&:hover': {
      color: 'black',
      background: theme.palette.primary.primaryGrey
    }
  }
}));
