import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme) => {
  return {
    mainContainer: { width: '100%', textAlign: 'center', marginTop: '4rem' },
    cardContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      columnGap: '2rem',
      justifyContent: 'center',
      marginTop: '2rem',
      boxSizing: 'border-box',
      [theme.breakpoints.up('md')]: {
        justifyContent: 'space-between'
      }
    }
  };
});
