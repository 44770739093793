import { useState, useEffect } from 'react';

const useIntersectionObserver = (targetId, options = {}, runMultiple = false) => {
  const [inView, setInView] = useState(false);
  const [hasTriggered, setHasTriggered] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setInView(true);
          if (!runMultiple) {
            setHasTriggered(true);
            observer.disconnect(); // Stop observing after the first trigger
          }
        } else if (runMultiple) {
          setInView(false);
        }
      });
    }, options);

    const target = document.getElementById(targetId);
    if (target) {
      observer.observe(target);
    }

    return () => {
      observer.disconnect();
    };
  }, [targetId, options, runMultiple]);

  return hasTriggered ? true : inView;
};

export default useIntersectionObserver;
