import Slide from '@material-ui/core/Slide';
import { useTheme } from '@material-ui/core';
import blende_grey from '../../../../assets/images/svg/blende_grey.svg';

import {
  IntroSectionWrapper,
  IntroSectionDiv,
  PortraitWrapper,
  PortraitInnerWrapper,
  PortraitImage,
  Name,
  useStyles,
  BlendeImageWrapper,
  BlendeImageWrapper2,
  HeroSection
} from './styles';
import GroupCodeField from 'components/Group/GroupCodeField/GroupCodeField';
import logoSvg from 'assets/images/svg/iskolaevkonyv_logo_szines-feliratnelkul.svg';
import Center from 'components/General/center/Center';

const IntroSection = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <IntroSectionWrapper className={classes.introSection}>
      <IntroSectionDiv>
        <Center element='section'>
          <HeroSection>
            <div className={classes.introText}>
              <h1>
                Üdvözlünk a <u className={classes.underline}>digitalizált iskolai fotózás</u> világában!
              </h1>
              <p>
                Rendeléshez kérjük add meg a kapott hozzáférési kódot, majd kattints a Belépésre,{' '}
                <a href='#customer_workflow' style={{ color: 'white' }}>
                  további információkért
                </a>{' '}
                pedig görgess lejjebb!
              </p>
              <GroupCodeField />
            </div>
            <PortraitWrapper>
              <PortraitInnerWrapper>
                <PortraitImage src={logoSvg} alt='Portrait of the photographer' />
              </PortraitInnerWrapper>
              <Name className={classes.introTitle}>Iskolaévkönyv</Name>
            </PortraitWrapper>
          </HeroSection>
        </Center>

        <BlendeImageWrapper2
          style={{
            background: `url(${blende_grey})`,
            backgroundSize: '200%',
            backgroundPositionX: '-12rem',
            backgroundPositionY: '-5.5rem'
          }}
        />
        <BlendeImageWrapper style={{ background: `url(${blende_grey})`, backgroundSize: '172%' }} />
      </IntroSectionDiv>
    </IntroSectionWrapper>
  );
};

export default IntroSection;
