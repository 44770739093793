import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

export const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1, 0),
    borderTop: '1px black solid',
    fontWeight: 'bold'
  },
  total: {
    fontWeight: 700,
    fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
    marginLeft: 'auto'
  },
  totalText: {
    fontWeight: 700,
    fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`
  },
  title: {
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: theme.spacing(2),
    fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`
  },
  listItemText: {
    margin: '0 20px',
    fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`
  },
  typographyPrice: {
    fontWeight: 'bold',
    fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
    marginLeft: 'auto'
  },
  data: {
    fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`
  },
  button: {
    marginTop: '30px',
    background: theme.palette.primary.backgroundSecondary,
    color: theme.palette.primary.backgroundPrimary,
    '&:hover': {
      background: theme.palette.primary.backgroundPrimary,
      color: theme.palette.primary.textSecondary
    }
  }
}));

export const MethodLogo = ({ src, alt, url }) => (
  <a href={url} target='_blank' rel='noreferrer'>
    <MethodLogoImage src={src} alt={alt} />
  </a>
);

export const MethodLogoImage = styled.img`
  max-width: 80px;
  max-height: 50px;
`;

export const DataGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 50%);
`;
export const DataWrapper = styled.div`
  overflow-wrap: break-word;
  text-align: center;
`;
