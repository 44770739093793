import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';
import SIZES from 'constants/sizes';

export const useStyles = makeStyles((theme) => ({
  img: {
    padding: '1rem',
    maxWidth: '200px',
    height: '200px',
    [theme.breakpoints.up('md')]: {
      padding: '0.5rem',
      maxWidth: '100px',
      height: '90px'
    }
  },
  itemName: {
    color: theme.palette.primary.backgroundPrimary,
    fontWeight: 400,
    textAlign: 'center',
    fontSize: '1rem',
    width: '15ch',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  itemDataOuter: {
    maxWidth: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  itemData: {
    color: theme.palette.primary.backgroundPrimary,
    fontWeight: 400,
    textAlign: 'center',
    fontSize: '0.75rem',
    whiteSpace: 'nowrap',
    margin: '15px'
  },
  itemCount: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateY(-50%) translateX(-50%)',
    fontSize: '1.25rem'
  },
  deleteBtn: {
    color: theme.palette.primary.backgroundPrimary,
    position: 'absolute',
    top: '0',
    right: '0',
    '&:hover, :focus': {
      color: theme.palette.primary.backgroundPrimary
    },
    [theme.breakpoints.up('md')]: {
      position: 'relative'
    }
  }
}));

export const ItemWrapper = styled.div`
  width: 100%;
  min-width: 320px;
  display: flex;
  position: relative;
  align-items: center;
  font-family: ${(props) => props.theme.typography.secondary}, Sans-serif, Arial !important;
  justify-content: center;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.palette.primary.backgroundPrimary};
  // border-top: 1px solid ${({ theme }) => theme.palette.primary.backgroundPrimary};
  @media (min-width: ${SIZES.MD}px) {
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 3fr 2fr 2fr 1fr;
  }
  &:nth-child(1) {
    border-top: 1px solid ${({ theme }) => theme.palette.primary.backgroundPrimary};
  }
`;

export const ItemData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  margin: auto;
`;
export const SubTotal = styled.div`
  margin: 15px;
`;
export const ItemCountWrapper = styled.div`
  display: flex;
  flexdirection: row;
  position: relative;
`;
export const DeleteBtnWrapper = styled.div`
  margin: auto;
  display: flex;
`;
