/** @format */

import React from 'react';
import { AccordionContainer, HomeWrapper, StyledLink, useStyles } from './styles';
import Center from 'components/General/center/Center';
import CustomAccordion from 'components/Accordion/Accordion';
import { Divider } from '@material-ui/core';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useTheme } from 'styled-components';
import { Helmet } from 'react-helmet';

const CustomerFAQ = () => {
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles(theme);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <HomeWrapper>
      <Helmet>
        <title>Iskolaévkönyv - Fotós GYIK</title>
        <meta
          name='description'
          content='Ismerje meg az iskolaévkönyv fotózással kapcsolatos gyakran ismételt kérdéseit (GYIK)! Fedezze fel a lehetőségeket, hogyan növelheti ügyfélszámát és láthatóságát a fotós szakmában.'
        />
      </Helmet>
      <section style={{ margin: '3rem 0', width: '100%' }}>
        <Center element='section'>
          <h1 style={{ textAlign: 'center', fontSize: 30 }}>Gyakori Kérdések Fotósoknak</h1>
          <p>
            Összegyűjtöttük a leggyakrabban előforduló kérdéseket, hogy könnyedén megtaláld a választ, és minden
            információ rendelkezésedre álljon.
          </p>
          <Divider />

          <AccordionContainer>
            Az Iskolaévkönyv portált azért hoztuk létre, hogy megkönnyítsük az <b>iskolai és óvodai fotózást</b> a
            Fényképész kollégák és az intézményekben dolgozók számára.
            <br />
            <br />A későbbiekben az egyszerűség kedvéért az alábbi elnevezéseket használjuk:
            <ul className={classes.list}>
              <li>
                <b>Intézmény:</b> Iskola vagy óvoda
              </li>
              <li>
                {' '}
                <b>Csoport:</b> Osztály vagy óvodai csoport
              </li>
              <li>
                {' '}
                <b>Fényképész:</b> A fotókat készítő fényképész kolléga
              </li>
              <li>
                {' '}
                <b>Megrendelő:</b> Szülők
              </li>
            </ul>
            <br />
            <CustomAccordion title={'Hogyan használható a rendszer?'}>
              <span>
                Amennyien szeretne regisztrálni, vegye fel velünk a kapcsolatot a{' '}
                <StyledLink href='mailto:regisztracio@iskolaevkonyv.hu'>regisztracio@iskolaevkonyv.hu</StyledLink>{' '}
                e-mail címen egy bemutatkozó levél elküldésével.
              </span>
              <br />
              <br />A regisztrációt követően linket küldünk e-mailben, amire rákattintva aktiválni szükséges a
              felhasználói profilt. Belépést követően lehetőséged van megadni a számlázási adatokat az ügyfeleknek
              történő automata számlázáshoz. Ehhez egy Számlázz.hu vagy egy Billingo fiókra van szükséged. Amint
              megtörtént a regisztráció, a fiók aktiválása és a számlázási beállítások megadása, a rendszer már
              használható is. Az adminisztrációs felületen keresztül van lehetőség az intézmény és azon belül a
              csoportok létrehozására és a csoportokhoz tartozó fotók feltöltésére. Az intézmények mindenképp, a
              csoportok opcionálisan egyedi kódot kaphatnak, tehát a megrendelő csak a saját gyermekének intézményéből
              tud vásárolni. <br />
              <br />A vásárlás folyamata az intézményhez tartozó belépési kód megadásától eltekintve megegyezik egy
              Online vásárlás folyamatával. A megrendelő a kapott kóddal belép az adott intézmény galériájába,
              kiválogatja a képeket és azok formátumát, valamint méretét, kosárba helyezi és kártyás fizetéssel
              (SimplePay) kifizeti. A digitális fotók
              {/* (SimplePay vagy Barion) kifizeti. A digitális fotók */}
              azonnal kiszállításra kerülnek a megrendelő e-mail címére és letölthetőek. <br />A papíralapú, előhívott
              képek a galéria lejárati ideje, vagy nyomtatásra küldése után kerülnek előhívásra és kiszállításra a
              fényképész részére.
            </CustomAccordion>
            <CustomAccordion title={'Mennyibe kerül az Iskolaévkönyv használata?'}>
              Az Iskolaévkönyvnek fix havi díja NINCS. <br />
              <br />A rendszer a fénykép eladások után jutalékot számol fel, ami adott időszakban fixen meghatározott és
              változása minimum 30 nappal előtte közlésre kerül. Ennek mértéke mindig az Általános szerződési
              feltételekben kerül meghatározásra. <br />
              <br />A nyomtatott fényképeknek (amennyiben az Iskolaévkönyv laborját veszi igénybe a Fényképész) van egy
              megszabott minimum ára, ami alatt nem értékesíthető a kép. A minimum ár az előhívási díjból,
              rendszerhasználati díjból és a kártyás fizetés díjából áll. A Fényképész bevételének értéke azonnal,
              transzparensen megjelenítésre kerül, amikor a Fényképész beárazza az eladásra kerülő egyes
              fényképtípusokat. <br />
              Ebben a jutalékban az alábbi szolgáltatások vannak beágyazva:
              <ul className={classes.list}>
                <li>Kártyás fizetés díja, beleértve a SimplePay jutalékot is</li>
                {/* <li>
                                    Kártyás fizetés díja, beleértve a SimplePay
                                    vagy Barion jutalékot is
                                </li> */}
                <li>
                  Opcionális automatikus számlázás. A rendszer automatikus számlát állít ki a Fényképész kolléga
                  Számlázz.hu vagy Billingo fiókjából.
                </li>
                <li>Tárhely használat a feltöltött fotók után – maximális tárolás 90 nap</li>
                <li>Digitális képek értékesítése</li>
                <li>
                  A papír alapú, előhívott képeket szortírozva, megrendelésenként (gyermekenként) csomagolva kapja meg a
                  fényképész. Csak a fotók intézménybe történő kiszállítása marad feladatként.
                </li>
                <li> GDPR megfelelőség</li>
                <li> Utórendelés lehetősége</li>
              </ul>
            </CustomAccordion>
            <CustomAccordion title={'Milyen fényképek árusítását teszi lehetővé a rendszer?'}>
              Az Iskolaévkönyv portálon keresztül az alábbi típusú fényképek értékesítése lehetséges és az alábbi
              díjakat számoljuk fel:
              <br />
              <br />
              <div className={classes.priceTableWrapper}>
                <table className={classes.table}>
                  <thead className={classes.thead}>
                    <td className={classes.row_item}>Képméret</td>
                    <td className={classes.row_item}>Kép típusa</td>
                    <td className={classes.row_item}>Előhívás díja</td>
                    <td className={classes.row_item}>Rendszerhasználati díj</td>
                    <td className={classes.row_item}>Kártyás fizetés díja</td>
                  </thead>
                  <tbody className={classes.tbody}>
                    <tr className={classes.trow}>
                      <td className={classes.row_item}>Eredeti kép</td>
                      <td className={classes.row_item}>digitális</td>
                      <td className={classes.row_item}>-</td>
                      <td className={classes.row_item}>15%</td>
                      <td className={classes.row_item}>0%</td>
                    </tr>
                    <tr className={classes.trow}>
                      <td className={classes.row_item}>1200x1800</td>
                      <td className={classes.row_item}>digitális</td>
                      <td className={classes.row_item}>-</td>
                      <td className={classes.row_item}>15%</td>
                      <td className={classes.row_item}>0%</td>
                    </tr>
                    <tr className={classes.trow}>
                      <td className={classes.row_item}>600x900</td>
                      <td className={classes.row_item}>digitális</td>
                      <td className={classes.row_item}>-</td>
                      <td className={classes.row_item}>15%</td>
                      <td className={classes.row_item}>0%</td>
                    </tr>
                    <tr className={classes.trow}>
                      <td className={classes.row_item}>9x13 cm</td>
                      <td className={classes.row_item}>előhívott</td>
                      <td className={classes.row_item}>45Ft</td>
                      <td className={classes.row_item}>15%</td>
                      <td className={classes.row_item}>0%</td>
                    </tr>
                    <tr className={classes.trow}>
                      <td className={classes.row_item}>10x15 cm</td>
                      <td className={classes.row_item}>előhívott</td>
                      <td className={classes.row_item}>55Ft</td>
                      <td className={classes.row_item}>15%</td>
                      <td className={classes.row_item}>0%</td>
                    </tr>
                    <tr className={classes.trow}>
                      <td className={classes.row_item}>13x18 cm</td>
                      <td className={classes.row_item}>előhívott</td>
                      <td className={classes.row_item}>90Ft</td>
                      <td className={classes.row_item}>15%</td>
                      <td className={classes.row_item}>0%</td>
                    </tr>
                    <tr className={classes.trow}>
                      <td className={classes.row_item}>20x30 cm</td>
                      <td className={classes.row_item}>előhívott</td>
                      <td className={classes.row_item}>300Ft</td>
                      <td className={classes.row_item}>15%</td>
                      <td className={classes.row_item}>0%</td>
                    </tr>
                    <tr className={classes.trow}>
                      <td className={classes.row_item}>30x45 cm</td>
                      <td className={classes.row_item}>előhívott</td>
                      <td className={classes.row_item}>600Ft</td>
                      <td className={classes.row_item}>15%</td>
                      <td className={classes.row_item}>0%</td>
                    </tr>
                    <tr className={classes.trow}>
                      <td className={classes.row_item}>2db 6x9 cm</td>
                      <td className={classes.row_item}>előhívott</td>
                      <td className={classes.row_item}>50Ft</td>
                      <td className={classes.row_item}>15%</td>
                      <td className={classes.row_item}>0%</td>
                    </tr>
                    <tr className={classes.trow}>
                      <td className={classes.row_item}>2db 7x10 cm</td>
                      <td className={classes.row_item}>előhívott</td>
                      <td className={classes.row_item}>60Ft</td>
                      <td className={classes.row_item}>15%</td>
                      <td className={classes.row_item}>0%</td>
                    </tr>
                    <tr className={classes.trow}>
                      <td className={classes.row_item}>4db igazolványkép</td>
                      <td className={classes.row_item}>előhívott</td>
                      <td className={classes.row_item}>100Ft</td>
                      <td className={classes.row_item}>15%</td>
                      <td className={classes.row_item}>0%</td>
                    </tr>
                    <tr className={classes.trow}>
                      <td className={classes.row_item}>9db bélyegkép</td>
                      <td className={classes.row_item}>előhívott</td>
                      <td className={classes.row_item}>120Ft</td>
                      <td className={classes.row_item}>15%</td>
                      <td className={classes.row_item}>0%</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <br />
              <br />
              2023.05.15-én aktuális áraknak felelnek meg. A rendszerhasználati díj %-ban meghatározott értéke a
              megadott értékesítési árhoz van viszonyítva. <br />
              <br />A képek konverzióját a rendszer automatikusan kezeli, annak megfelelően, hogy a megrendelő milyen
              felbontású képet vásárolt.
              <br />
              <br />A digitális képek kártyás fizetés után automatikusan kiküldésre kerülnek a megrendelő e-mail címére.{' '}
              <br />
              <br />A fényképek árazását a Fényképész egyedileg végezheti el, akár intézményenként és csoportonként is
              eltérő árakat megszabva.
            </CustomAccordion>
            <CustomAccordion title={'Meddig érhetőek el a fotók a portálon?'}>
              Az egyes csoportok érvényességét a Fényképész egyedileg állíthatja be, külön lejáratot megszabva az
              előhívásra kerülő, papír alapú fotóknak és a digitális képeknek. <br />
              <br />A lejárati időn belül van lehetőség a fotók megvásárlására. A lejárt vagy nyomtatásra küldött
              galéria a későbbiekben nyomtatott képek rendelésére már nem nyitható vissza, digitális képek rendelésére
              viszont igen, így a megrendelőknek lehetőségük van utórendelés leadására digitális formátumban. <br />
              <br />A papíralapú képeknél az osztály (galéria) lejárati dátuma után kerülnek előhívásra a képek, amiket
              a Fényképész megrendelésenként csomagolva kap kézhez.
              <br />
              <br />
              Az egyes galériákból a képek GDPR szerint, a keletkezéstől számított 90 nap után automatikusan törlésre
              kerülnek.
            </CustomAccordion>
          </AccordionContainer>
          <Divider />
          <br />
          <div>
            A Vásárlóknak szóló <b>Gyakori Kérdések</b> oldalunk{' '}
            <StyledLink
              href='/customer-faq'
              onClick={(e) => {
                e.preventDefault();
                history.push('/customer-faq');
              }}>
              ezen a linken
            </StyledLink>{' '}
            érhető el.
          </div>
        </Center>
      </section>
    </HomeWrapper>
  );
};

export default CustomerFAQ;
