import React from 'react';
import { MetricsContainer, MetricBox, MetricNumber, MetricLabel } from './styles'; // Adjust path accordingly
import CountUp from 'react-countup';
import useIntersectionObserver from 'hooks/useIntersectionObserver';

const MetricsSection = () => {
  const inView = useIntersectionObserver('metricsSection', { threshold: 0.5 });

  return (
    <MetricsContainer id='metricsSection'>
      <MetricBox>
        <MetricNumber>{inView && <CountUp start={0} end={10000} duration={2} suffix='+' />}</MetricNumber>
        <MetricLabel>vásárló</MetricLabel>
      </MetricBox>
      <MetricBox>
        <MetricNumber>{inView && <CountUp start={0} end={200} duration={2} suffix='+' />}</MetricNumber>
        <MetricLabel>fényképezett iskola</MetricLabel>
      </MetricBox>
      <MetricBox>
        <MetricNumber>{inView && <CountUp start={0} end={60000} duration={2} suffix='+' />}</MetricNumber>
        <MetricLabel>fénykép értékesítés</MetricLabel>
      </MetricBox>
      <MetricBox>
        <MetricNumber>{inView && <CountUp start={0} end={20} duration={2} suffix='+' />}</MetricNumber>
        <MetricLabel>megmentett fa</MetricLabel>
      </MetricBox>
    </MetricsContainer>
  );
};

export default MetricsSection;
