import { useEffect, useState } from 'react';
import { useTheme, TextField, Button, FormControlLabel } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import randomShopOwner from 'assets/images/randomphotographer.jpg';
import invertedLogo from 'assets/images/svg/iskolaevkonyv_logo_szines-feliratnelkul.svg';
import { Formik, Form } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import { ContactSchema } from 'validation/Contact/Schemas';
import { Divider } from '@material-ui/core';

import { useStyles } from './styles';
import { useContactAPI } from './hooks/useContactAPI';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { MdOutlineAccountCircle, MdOutlineEmail, MdOutlinePhone } from 'react-icons/md';
import Center from 'components/General/center/Center';
import contact_image from 'assets/images/svg/contact.svg';
import { Checkbox } from '@mui/material';

const Contact = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const { sendEmail } = useContactAPI();
  const [privacyAccepted, setPrivacyAccepted] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const [addressData] = useState(
    props?.shopData
      ? [
          {
            name: 'Email cím',
            data: props?.shopData?.owner?.contact?.email ? props?.shopData?.owner?.contact?.email : 'Nincs megadva',
            icon: <MdOutlineEmail style={{ color: 'black' }} size={20} />
          },
          {
            name: 'Telefonszám',
            data: props?.shopData?.owner?.contact?.phone ? props?.shopData?.owner?.contact?.phone : 'Nincs megadva',
            icon: <MdOutlinePhone style={{ color: 'black' }} size={20} />
          }
        ]
      : [
          {
            name: 'Email cím',
            data: props?.shopData?.contact?.email,
            icon: <MdOutlineEmail style={{ color: 'black' }} size={20} />
          },
          {
            name: 'Fotós regisztráció',
            data: 'regisztracio@iskolaevkonyv.hu',
            icon: <MdOutlineAccountCircle style={{ color: 'black' }} size={20} />
          }
        ]
  );

  useEffect(() => {
    document.body.style.overflow = 'visible';
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const shopId = useSelector((state) => state.nonPersistedReducers?.shopSliceReducer?.shopId);

  const captchaRef = useRef();

  return (
    <main className={classes.container}>
      <Helmet>
        <title>Iskolaévkönyv - Kapcsolat</title>
        <meta
          name='description'
          content='Lépj kapcsolatba velünk, ha kérdésed van intézményi fotózás, iskolai fotózás vagy bölcsődei fotózás témában.'
        />
      </Helmet>
      <div className={classes.center}>
        <Slide direction='down' in={true} timeout={1000} unmountOnExit>
          <div className={classes.contactContainer}>
            <div>
              <div className={classes.header}>
                <div className={classes.portraitWrapper}>
                  <img
                    className={classes.portraitImage}
                    style={{
                      maxWidth: props?.shop && '200px',
                      maxHeight: props?.shop && '200px'
                    }}
                    src={props?.shop ? randomShopOwner : invertedLogo}
                    alt='Portrait of the photographer'
                  />
                </div>
                <div className={classes.name}>
                  {`${props?.shopData?.owner?.contact?.firstname} ${
                    props?.shopData?.owner?.contact?.lastname ? props?.shopData?.owner?.contact?.lastname : ''
                  }`}
                </div>
              </div>
              <p>
                Töltsd ki a kapcsolati űrlapot, vagy lépj velünk kapcsolatba e-mailben/telefonon. Ügyfélszolgálatunk
                készséggel segít mindenben, legyen szó kérdésekről, ötletekről vagy visszajelzésekről.
              </p>
              <p>
                Célunk, hogy a lehető legjobb élményt nyújtsuk, és minden igényedre személyre szabott megoldást
                találjunk. Munkatársaink elkötelezettek abban, hogy gyors és hatékony támogatást nyújtsanak, mert
                tudjuk, hogy az időd értékes.
              </p>
              <br />
              {addressData &&
                addressData?.map((address) => (
                  <div key={address.name} className={classes.datas}>
                    <div>{address.icon}</div>
                    <div
                      style={{
                        wordBreak: 'break-word', // Tördelje a szavakat
                        overflowWrap: 'break-word', // Alternatív mód modern böngészőkhöz
                        whiteSpace: 'normal', // Engedélyezi a szöveg tördelését
                        maxWidth: '100%' // Biztosítsd, hogy ne legyen túl széles
                      }}>
                      {address.data}
                    </div>
                  </div>
                ))}
              <img
                alt='Intézményeket keresünk az iskolaévkönyvhöz.'
                src={contact_image}
                className={classes.contactIllustration}
              />
            </div>

            <div className={classes.formContainer}>
              <Divider orientation='vertical' className={classes.verticalDivider} />
              <Divider orientation='horizontal' className={classes.horizontalDivider} />
              <Formik
                enableReinitialize
                initialValues={{
                  name: formData.name,
                  email: formData.email,
                  message: formData.message,
                  shopId: shopId > 0 ? shopId : null
                }}
                style={{ width: '100%' }}
                validate={false}
                validationSchema={ContactSchema}
                onSubmit={async (values, { setSubmitting, resetForm, setFieldValue }) => {
                  setFormData({
                    name: values.name,
                    email: values.email,
                    message: values.message
                  });

                  const token = captchaRef.current.getValue();
                  captchaRef.current.reset();
                  if (token) {
                    await sendEmail({
                      values: values,
                      resetForm: setFieldValue,
                      token: token
                    });
                  }
                }}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, resetForm }) => (
                  <Form onSubmit={handleSubmit} className={classes.formWrapper}>
                    <h1>Kérdésed van?</h1>
                    {/* Forms */}
                    <TextField
                      id='name'
                      name='name'
                      autoComplete='name'
                      value={values.name}
                      autoFocus
                      label='Neved'
                      onChange={handleChange}
                      error={touched.name && Boolean(errors.name)}
                      helperText={touched.name && errors.name}
                      variant='outlined'
                    />
                    <TextField
                      id='email'
                      name='email'
                      autoComplete='email'
                      value={values.email}
                      label='Email címed'
                      onChange={handleChange}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                      variant='outlined'
                    />
                    <TextField
                      variant='outlined'
                      name='message'
                      label='Üzeneted'
                      fullWidth
                      margin='normal'
                      multiline
                      rows={6}
                      value={values.message}
                      onChange={handleChange}
                      error={touched.message && Boolean(errors.message)}
                      helperText={touched.message && errors.message}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={privacyAccepted} onChange={() => setPrivacyAccepted(!privacyAccepted)} />
                      }
                      label={
                        <>
                          Az{' '}
                          <a
                            href={`${window._env_.REACT_APP_QDAK_REGISTRATION_URL?.split('admin.')[0]}${
                              window._env_.REACT_APP_QDAK_REGISTRATION_URL?.split('admin.')[1]?.split('/register')[0]
                            }/adatkezelesi_tajekoztato`}
                            target='_blank'
                            rel='noreferrer'>
                            Adatkezelési tájékoztatót
                          </a>{' '}
                          elolvastam, az abban foglaltakat elfogadom.
                        </>
                      }
                    />
                    <ReCAPTCHA
                      sitekey={window._env_.REACT_APP_RECAPTCHA_SITE_KEY}
                      ref={captchaRef}
                      onChange={() => {
                        setDisabled(
                          captchaRef?.current?.getValue() == '' || captchaRef?.current?.getValue() == undefined
                        );
                      }}
                    />
                    <Button
                      className={classes.submitBtn}
                      type='submit'
                      color='primary'
                      onClick={handleSubmit}
                      disabled={disabled || !privacyAccepted}>
                      Küldés
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Slide>
      </div>
    </main>
  );
};

export default Contact;
