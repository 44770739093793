import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export const StyledLink = styled.a`
  color: ${({ theme }) => theme.palette.primary.backgroundSecondary};
`;

export const HomeWrapper = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: rgb(241, 245, 249);
  section {
    margin: unset;
  }
`;

export const AccordionContainer = styled.div`
  margin: 2rem 0;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  @media (min-width: 480px) {
    margin: 3rem 1rem;
  }
`;

export const useStyles = makeStyles((theme) => ({}));
