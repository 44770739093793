import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import React from 'react';
import { MdExpandMore } from 'react-icons/md';
import { Hashtag, Title, useStyles } from './styles';
import { useTheme } from 'styled-components';

const CustomAccordion = ({ title, children }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Accordion className={classes.container}>
      <AccordionSummary aria-controls='panel1-content' id='panel1-header' expandIcon={<MdExpandMore />}>
        <Title>
          <Hashtag>#</Hashtag> {title}
        </Title>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>{children}</AccordionDetails>
    </Accordion>
  );
};

export default CustomAccordion;
