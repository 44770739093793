import { useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { useGroupAPI } from '../hooks/useGroupAPI';
import Zoom from '@material-ui/core/Zoom';

import { useStyles, HiddenCategoryText } from './styles';

const GroupCard = (props) => {
  const { belongsToId, groupName, groupCategory, groupId, imgSrc } = props;

  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();

  const { cover, fetchCover } = useGroupAPI();

  useEffect(() => {
    if (localStorage.getItem('shopId')) {
      fetchCover({
        shopId: localStorage.getItem('shopId'),
        id: groupId
      });
    }
  }, [groupId, localStorage.getItem('shopId')]);

  const calcName = (name) => {
    let displayedName = name;

    if (name.length > 25) {
      displayedName = name.slice(0, 10) + '...' + name.slice(name.length - 10, name.length);
    }

    return displayedName;
  };

  const routeChange = () => {
    localStorage.setItem('parentGroupId', belongsToId.toString());
    let path = `/gallery/${groupId}`;
    history.push(path, {
      groupId: groupId,
      groupName: groupName,
      groupCategory: groupCategory
    });
  };

  const [hover, setHover] = useState(false);

  return (
    <Zoom in={true} timeout={700} unmountOnExit>
      <Card
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={{ position: 'relative' }}
        className={hover ? classes.hoverCardStyle : classes.cardStyle}
        onClick={routeChange}>
        <HiddenCategoryText>{groupCategory}</HiddenCategoryText>
        <CardActionArea>
          <CardMedia className={classes.media} image={cover ? cover : imgSrc}></CardMedia>
          <CardContent className={classes.titleWrapper}>
            <Tooltip title={groupName}>
              <Typography className={classes.cardTitle} variant='body1'>
                {calcName(groupName)}
              </Typography>
            </Tooltip>
          </CardContent>
        </CardActionArea>
      </Card>
    </Zoom>
  );
};

export default GroupCard;
