import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: '100%'
  },
  submitBtn: {
    marginTop: theme.spacing(2),
    color: 'white',
    backgroundColor: theme.palette.primary.backgroundPrimary,
    '&:hover': {
      backgroundColor: theme.palette.primary.backgroundPrimary,
      opacity: '80%'
    },
    '&:disabled': {
      color: 'rgb(50,50,50)'
    }
  },
  center: {
    paddingInline: '1.5rem',
    [theme.breakpoints.up(1250)]: {
      paddingInline: 'calc(50% - 40.25rem)'
    }
  },

  portraitWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '1rem 0'
  },
  portraitImage: {
    width: '30vw',
    height: '30vw',
    maxWidth: '100px',
    maxHeight: '100px',
    borderRadius: '50%',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.22), 0 6px 20px 0 rgba(0, 0, 0, 0.22)',
    border: `2px solid ${theme.palette.primary.backgroundPrimary}`,
    backgroundColor: theme.palette.primary.backgroundContent
  },
  name: {
    fontSize: '1.5rem',
    fontWeight: 600,
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    textAlign: 'center'
  },
  datas: {
    display: 'flex',
    gap: '1rem',
    marginTop: '1rem'
  },
  contactContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    margin: '5rem 0',
    gap: '1rem',
    [theme.breakpoints.down(800)]: {
      display: 'flex',
      flexDirection: 'column',
      margin: '2rem 0'
    }
  },
  formContainer: {
    display: 'flex',
    gap: '2rem',
    [theme.breakpoints.down(800)]: {
      flexDirection: 'column'
    }
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    gap: '2rem',
    [theme.breakpoints.down(800)]: {
      flexDirection: 'column',
      gap: '0'
    }
  },
  verticalDivider: {
    [theme.breakpoints.down(800)]: {
      display: 'none'
    }
  },
  horizontalDivide: {
    display: 'none',
    [theme.breakpoints.down(800)]: {
      display: 'unset'
    }
  },
  contactIllustration: {
    width: '90%',
    marginTop: '1rem'
  }
}));
