import { makeStyles } from '@material-ui/core/styles';
import sizes from 'constants/sizes';
import styled from 'styled-components';

export const useStyles = makeStyles((theme) => ({
  layout: {
    display: 'flex',
    flexDirection: 'column',
    width: 'auto',
    alignItems: 'center',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: '100%',
      maxWidth: '600px',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },

  link: {
    cursor: 'pointer'
  },

  stepper: {
    background: 'transparent',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row !important'
    }
  },
  stepperItems: {
    marginTop: '0.2rem',
    color: theme.palette.primary.textSecondary,
    fontWeight: 'bold',
    '& .MuiTypography-root': {
      fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`
    },
    alignSelf: 'center',
    textTransform: 'uppercase !important',
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row !important',
      marginTop: '2rem'
    }
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '730px',
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    position: 'relative',
    borderRadius: '10px',
    height: '100%',
    width: '100%',
    zIndex: 10,
    maxWidth: '600px',
    boxShadow: '0 4px 15px 0 rgb(0 0 0 / 30%), 0 6px 20px 0 rgb(0 0 0 / 19%)',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(20),
      padding: theme.spacing(3)
    }
  },

  buttons: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  },

  breadcrumb: {
    margin: '1rem',
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`
  },
  paperTitle: {
    display: 'flex',
    background: theme.palette.primary.backgroundSecondary,
    borderRadius: '10px 10px 0 0',
    marginTop: '-16px',
    alignItems: 'center',
    justifyContent: 'center',
    width: '110%',
    height: '100px',
    color: theme.palette.primary.backgroundPrimary,
    boxShadow: '0 4px 4px -2px gray',
    [theme.breakpoints.up('sm')]: {
      marginTop: '-24px',
      width: '600px'
    }
  }
}));

export const CheckoutBack = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 1rem;
  background: ${(props) => props.theme.palette.primary.backgroundSecondary};
  opacity: 35%;
  position: absolute;
  transform: rotate(-3deg);
  z-index: -1;
  left: 4rem;
  display: none;
  @media (min-width: ${sizes.MD}px) {
    display: block;
  }
`;
export const CheckoutBack2 = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 1rem;
  background: ${(props) => props.theme.palette.primary.backgroundSecondary};
  opacity: 20%;
  position: absolute;
  transform: rotate(-6deg);
  z-index: -1;
  left: 6rem;
  display: none;
  @media (min-width: ${sizes.MD}px) {
    display: block;
  }
`;
