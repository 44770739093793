import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';
import sizes from 'constants/sizes';
import folyamat_vonal from 'assets/images/svg/folyamat_vonal.svg';

export const HomeWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  min-height: 3700px;
  @media (min-width: ${sizes.SM}px) {
    min-height: 2450px;
  }

  @media (min-width: ${sizes.MD}px) {
    min-height: 3070px;
  }
`;

export const StartButton = styled.button`
  background:${({ theme }) => theme.palette.primary.backgroundSecondary};
  color: ${({ theme }) => theme.palette.primary.backgroundPrimary};
  border: none;
  width: 100%;
  height: 40px;
  cursor: pointer;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  border-Radius: 5px;
  font-size: 1rem;
  align-self: center;
  transition: background 300ms;
  font-family:${({ theme }) => theme.typography.main}, Sans-serif, Arial;
  @media (min-width: 480px) {
    margin-top: 0.4rem;
  }
  &:hover {
    opacity: 80%;
  },`;

export const IconLink = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 150;
  bottom: 12rem;
  right: 3rem;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: transparent;
  background: ${({ theme }) => theme.palette.primary.backgroundSecondary};
  transition: all 0.3s;
  color: white;
  svg {
    font-size: 50px;
  }
  &:hover {
    color: white;
    background: black;
  }
`;

export const PairContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  grid-gap: 1rem;
  margin: 2rem;
  @media (min-width: 900px) {
    grid-gap: 2rem;
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 1200px) {
    margin: 5rem 2rem;
    grid-gap: 5rem;
  }
`;

export const WidgetContainer = styled.div`
  margin: 2rem;
  display: flex;
  flex-wrap: wrap;
  // align-items: center;
  justify-content: center;
`;
// export const WidgetContainer = styled.div`
//   margin: 2rem;
//   display: grid;
//   grid-gap: 1rem;
//   grid-template-columns: 1fr;
//   @media (min-width: 768px) {
//     grid-template-columns: 1fr 1fr;
//     grid-gap: 3rem;
//   }
//   @media (min-width: 1100px) {
//     grid-gap: 5rem;
//     grid-template-columns: 1fr 1fr 1fr 1fr;
//   }
// `;

export const ContactButton = styled.a`
background: ${({ theme }) => theme.palette.primary.backgroundSecondary};
color: ${({ theme }) => theme.palette.primary.backgroundPrimary};
width: 250px;

cursor: pointer;
text-align: center;
height: 30px;
line-height: 30px;
text-decoration: none;
border-Radius: 5px;
font-size: 1rem;
align-self: center;
transition: background 300ms;
@media (min-width: 900px) {
  margin-right: 2rem;
}
&:hover {
  background:${({ theme }) => theme.palette.primary.backgroundPrimary};
  color: ${({ theme }) => theme.palette.primary.textSecondary};
},`;
export const StyledLink = styled.a`
  color: ${({ theme }) => theme.palette.primary.backgroundSecondary};
`;

export const CustomerSideContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;

  @media (min-width: 768px) {
    flex-direction: row;
    gap: 3rem;
  }
  @media (min-width: 992px) {
    padding: 5rem 2rem;
    gap: 5rem;
  }
  @media (min-width: 1200px) {
    gap: 10rem;
  }
`;

export const Description = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const ProcessEnd = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.palette.primary.backgroundSecondary};
  place-self: center;
`;

export const ProcessIcons2 = styled.div`
  display: flex;
  align-self: start;
  padding: 0.6rem 0.7rem;
  border-radius: 2.5rem;
  background-color: ${({ theme }) => theme.palette.primary.backgroundSecondary};
  box-shadow: 0 0 12px -5px var(--p-900);
`;

export const ProcessIcons = styled.div`
  display: flex;
  align-self: start;
  padding: 0.9rem 0.9rem;
  border-radius: 2.5rem;
  background-color: ${({ theme }) => theme.palette.primary.backgroundSecondary};
  box-shadow: 0 0 12px -5px var(--p-900);
`;

export const ProcessDescription = styled.div`
  text-align: left;

  h3 {
    margin: unset;
    margin-top: 0rem;
  }

  p {
    margin: 0.5em auto 0;
  }
`;

export const ProcessContainer = styled.div`
  display: grid;
  grid-template-columns: calc(1.2rem + 40px) auto;
  grid-gap: 3.5rem 1.5rem;
  gap: 3.5rem 1.5rem;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  margin-top: 3rem;
  margin-bottom: 3rem;
  &::before {
    content: '';
    position: absolute;
    top: 0.75rem;
    bottom: 0.9rem;
    left: calc(calc(1.2rem + 40px) / 2 - 0.25rem);
    width: 0.5rem;
    background: url(${folyamat_vonal}) repeat-y;
    background-size: contain;
    z-index: -1;
  }
`;

export const useStyles = makeStyles((theme) => ({
  allGroupsBtn: {
    background: theme.palette.primary.backgroundSecondary,
    color: theme.palette.primary.textSecondary,
    transition: 'opacity 300ms',
    opacity: '80%',
    '&:hover': {
      opacity: '100%',
      background: theme.palette.primary.backgroundSecondary
    }
  },
  list: {
    listStyleImage: `linear-gradient(to left bottom, ${theme.palette.primary.backgroundSecondary}, ${theme.palette.primary.backgroundSecondary})`,
    display: 'flex',
    flexDirection: 'column',
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    '& li': {
      marginTop: '0.5rem'
    }
  },

  widget: {
    width: '260px',
    padding: '1rem'
  },
  links: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '5rem',
    height: 320,
    gap: '5rem',
    [theme.breakpoints.down(610)]: {
      height: 'unset',
      flexDirection: 'column',
      gap: '1rem',
      marginTop: '3rem'
    }
  },
  linkWrapper: {
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto',
    '& img': {
      width: 300,
      height: 300,
      [theme.breakpoints.down(800)]: {
        width: '250px',
        height: '250px'
      }
    },
    '&:hover': {
      opacity: '80%'
    }
  },

  divider: {
    [theme.breakpoints.down(610)]: {
      width: '90vw',
      height: 1
    }
  },

  workflowImage: {
    width: '70%',
    [theme.breakpoints.down(sizes.SM)]: {
      width: '100%'
    }
  }
}));
