import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export const StyledLink = styled.a`
  color: ${({ theme }) => theme.palette.primary.backgroundSecondary};
`;

export const HomeWrapper = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: rgb(241, 245, 249);
  align-items: center;
  section {
    margin: unset;
  }
`;

export const AccordionContainer = styled.div`
  margin: 2rem 0rem;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  @media (min-width: 560px) {
    margin: 3rem 1rem;
  }
`;

export const useStyles = makeStyles((theme) => ({
  list: {
    listStyleImage: `linear-gradient(to left bottom, ${theme.palette.primary.backgroundSecondary}, ${theme.palette.primary.backgroundSecondary})`,
    display: 'flex',
    flexDirection: 'column',
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    '& li': {
      marginTop: '0.5rem'
    }
  },
  priceTableWrapper: {
    width: '100%',
    margin: 0,
    padding: 0,
    overflow: 'auto'
  },
  table: {
    width: '100%',
    minWidth: '300px',
    margin: 0,
    padding: 0,
    border: `1px solid ${theme.palette.primary.backgroundSecondary}`,
    borderRadius: '2rem',
    overflow: 'hidden'
  },
  thead: { background: theme.palette.primary.backgroundSecondary, margin: 0, padding: 0 },
  tbody: { margin: 0, padding: 0 },
  row_item: {
    margin: 0,
    padding: 0,
    textAlign: 'center',
    border: `1px solid ${theme.palette.primary.backgroundSecondary}`
  }
}));
