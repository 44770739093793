/** @format */

import React from 'react';
import { AccordionContainer, HomeWrapper, StyledLink } from './styles';
import Center from 'components/General/center/Center';
import CustomAccordion from 'components/Accordion/Accordion';
import { Divider } from '@material-ui/core';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Helmet } from 'react-helmet';

const CustomerFAQ = () => {
  const history = useHistory();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <HomeWrapper>
      <Helmet>
        <title>Iskolaévkönyv - Vásárlói GYIK</title>
        <meta
          name='description'
          content='Fedezze fel a válaszokat a gyakran felmerülő kérdésekre gyermekfotózással kapcsolatban! Ismerje meg, hogyan zajlik az intézményi fotózás folyamata, hogyan működik a rendelés folyamata, és találja meg a legjobb megoldásokat az óvodai, bölcsődei és iskolai fotókhoz.'
        />
      </Helmet>
      <section style={{ margin: '3rem 0' }}>
        <Center element='section' s>
          <h1 style={{ textAlign: 'center', fontSize: 30 }}>Gyakori Kérdések Vásárlóknak</h1>
          <p>
            Összegyűjtöttük a leggyakrabban előforduló kérdéseket, hogy könnyedén megtaláld a választ, és minden
            információ rendelkezésedre álljon.
          </p>
          <Divider />
          <AccordionContainer>
            <CustomAccordion title={'Hol vehetem át a fényképeket?'}>
              A fényképeket az osztályfőnökök osztják ki, néhány nappal a galéria lejárata után, így a vevőknek nem kell
              szállítási díjat fizetni.
            </CustomAccordion>
            <CustomAccordion title={'Mit jelent a galéria lejárata?'}>
              Amikor lejár a galéria akkor már többé nem lehet papírképeket vásárolni, csak digitális formátumok
              vásárlására van lehetőség, erre azért van szükség, hogy szállítási költség nélkül egy munkamenetben
              mindenki megkaphassa a fényképeket, az iskolát ne terheljük heteken keresztül a fényképek ügyintézésével,
              illetve aki az első napokban rendel azoknak ne kelljen sokáig várniuk a papírképeikre.
            </CustomAccordion>
            <CustomAccordion
              title={'Kell-e fizetni akkor is ha készültek képek a gyermekemről, de nem szeretnék vásárolni belőlük?'}>
              A fényképezésért és a galéria megtekintéséért nem kell fizetnie senkinek, ez egy lehetőség, hogy a diák
              évek néhány pillanatát megörökítsük és ha tetszik a fénykép akkor a szülők megvásárolhassák a képeket.
            </CustomAccordion>
            <CustomAccordion title={'Mennyibe kerül a képek kiszállítása az iskolába?'}>
              A képek szállításáért nem kell fizetni, mivel az egész iskola egy munkamenetben rendel, így a szállítási
              költségek egészen minimálisak, nincs értelme rendelésekre lebontani.
            </CustomAccordion>
            <CustomAccordion title={'Kérhetem-e, hogy a gyermekemet ne fényképezzék le az iskolában?'}>
              Igen, ha nem szeretné, hogy a gyermekét lefényképezzük akkor töltse ki az oldalunkon található elutasító
              nyilatkozatot vagy egyszerűen beszéljék meg a gyerekkel, hogy ne vegyen részt a fotózáson, mi nem
              erőltetünk senkit, ha látjuk, hogy valamiért tartózkodnak a gyerekek a fényképezéstől.
            </CustomAccordion>
            <CustomAccordion title={'Mit tegyek ha nem tetszenek a megvásárolt papírképek?'}>
              Ha minőségi kifogása van a papírképek kidolgozásával kapcsolatban akkor írjon nekünk a megadott e-mail
              címre és felvesszük Önnel a kapcsolatot:{' '}
              <StyledLink href='mailto:ugyfelszolgalat@iskolaevkonyv.hu'>ugyfelszolgalat@iskolaevkonyv.hu</StyledLink>
            </CustomAccordion>
            <CustomAccordion title={'Kell-e regisztrálni a vásárláshoz?'}>
              A vásárláshoz nem szükséges regisztráció.
            </CustomAccordion>
            <CustomAccordion title={'Hogy történik a rendelések kifizetése?'}>
              A fizetést bankkártyával, illetve utalással is lehet intézni a webáruházaknál megszokott módon.
              Átutalásnál a rendeléstől számított 72 órán belül szükséges a teljesítés és az átutalás feldolgozása 2
              munkanapot is igénybe vehet.
            </CustomAccordion>
            <CustomAccordion title={'Kérhetek-e a vállalkozásom nevére számlát?'}>
              A 2022 szeptember 1.-étől érvényes adójogszabályok szerint csak magánszemélyek vásárolhatnak a
              webáruházunkban.
            </CustomAccordion>
            <CustomAccordion title={'Hogyan tudok belépni az iskola galériájába?'}>
              <span>
                Az&nbsp;
                <StyledLink
                  href='/'
                  onClick={(e) => {
                    e.preventDefault();
                    history.push('/');
                  }}>
                  iskolaevkonyv.hu
                </StyledLink>
                &nbsp;oldalon a megadott jelszóval.
              </span>
            </CustomAccordion>
            <CustomAccordion title={'Hogyan és meddig tudom letölteni a megvásárolt digitális képeket?'}>
              A digitális formátumban megvásárolt képeket pár perccel a rendelés kifizetése után le lehet tölteni.{' '}
              <br /> <br />
              Fontos tudni, hogy ezek a fájlok csak két hétig elérhetők, ezért töltsék le a saját gépükre!
              <br /> <br />A letöltési linket a vásárlásnál megadott e-mail címre küldi el a rendszer, ezért kérjük,
              hogy ügyeljen arra, hogy ezt pontosan adja meg a vásárlásnál!
            </CustomAccordion>
            <CustomAccordion title={'Tovább adhatom a jelszót idegeneknek?'}>
              A galériához való hozzáférést mi kizárólag az iskola részére küldjük el, adatkezelési szempontból ezt
              tovább adni aggályos . . .
            </CustomAccordion>
          </AccordionContainer>
          <Divider />
          <br />
          <div>
            A Fotósoknak szóló <b>Gyakori Kérdések</b> oldalunk{' '}
            <StyledLink
              href='/photographer-faq'
              onClick={(e) => {
                e.preventDefault();
                history.push('/photographer-faq');
              }}>
              ezen a linken
            </StyledLink>{' '}
            érhető el.
          </div>
        </Center>
      </section>
    </HomeWrapper>
  );
};

export default CustomerFAQ;
