import { makeStyles, withStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';

export const StyledBadge = withStyles((theme) => ({
  badge: {
    border: `2px solid ${theme.palette.primary.textPrimary}`,
    padding: '0 4px',
    backgroundColor: theme.palette.primary.backgroundPrimary,
    color: theme.palette.primary.textPrimary
  }
}))(Badge);

export const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 100
  },
  appbar: {
    height: '75px',
    width: '100%',
    zIndex: '1100',
    position: 'fixed',
    backgroundColor: theme.palette.primary.backgroundPrimary,
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    '@media (min-width: 1100px)': {
      position: 'relative'
    }
  },
  toolbar: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  menuButton: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  menuIcon: {
    fontSize: '2rem'
  },
  listItemText: {
    fontSize: '0.9rem !important',
    textTransform: 'uppercase',
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`
  },
  bigListItemText: {
    textTransform: 'uppercase',
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    color: theme.palette.primary.textPrimary
  },
  titleWrapper: {
    flexGrow: 1,
    textAlign: 'start',
    width: '100%'
  },
  title: {
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    margin: '0 auto',
    flexGrow: 1,
    textAlign: 'start',
    maxWidth: '150px',
    width: '100%',
    maxHeight: '55px',
    color: theme.palette.primary.textPrimary,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
      cursor: 'pointer',
      outline: 'none !important'
    },
    '&:focus': {
      outline: 'none !important'
    }
  },
  smallList: {
    width: 250,
    fontSize: '1rem'
  },
  bigList: {
    display: 'flex'
  },
  paper: {
    backgroundColor: theme.palette.primary.backgroundPrimary,
    color: theme.palette.primary.textPrimary,
    width: 270
  },
  listItemIcon: {
    color: theme.palette.primary.textPrimary,
    fontSize: '1.5rem'
  },
  logoutItem: {
    color: theme.palette.primary.textPrimary,
    fontSize: '25px'
  },
  listItem: {
    position: 'relative',
    color: theme.palette.primary.textPrimary,
    whiteSpace: 'nowrap',
    width: '100%',
    textTransform: 'uppercase',
    textDecoration: 'none',
    letterSpacing: '0.15em',
    padding: '15px 20px',

    '&:hover, &:focus': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
    '&:focus': {
      transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      outline: 'none !important'
    }
  },
  activeItem: {
    position: 'relative',
    color: theme.palette.primary.backgroundSecondary,
    whiteSpace: 'nowrap',
    width: '100%',
    textTransform: 'uppercase',
    textDecoration: 'none',
    letterSpacing: '0.15em',
    padding: '15px 20px',
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    outline: 'none !important'
  },
  collapse: {
    border: `1px solid ${theme.palette.primary.textPrimary}`,
    borderBottomLeftRadius: '11px',
    borderBottomRightRadius: '11px',
    backgroundColor: theme.palette.primary.backgroundPrimary,
    color: theme.palette.primary.textPrimary,
    position: 'absolute',
    top: '100%',
    left: '0',
    zIndex: '100',
    '& wrapperInner': {
      border: '5px solid red'
    }
  },
  smallMenu: {
    color: theme.palette.primary.textPrimary,
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center'
    },
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  bigMenu: {
    color: theme.palette.primary.textPrimary,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  }
}));
