import { useEffect, useState } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';

import { useStyles } from './styles';

const SelectStep = (props) => {
  const classes = useStyles();
  const handleAnswer = (id) => {
    props.setAnswer(id);
    setAnswer(id);
  };
  const [answer, setAnswer] = useState(props.step.answer === null ? '' : props.step.answer);
  const [disabled, setDisabled] = useState(false);
  const stepNumber = props.step.id;

  const getModifiers = () => {
    let modifiers = [];
    props.steps.forEach((step) => {
      step.options.forEach((option) => {
        modifiers.push(option);
      });
    });
    return modifiers;
  };

  const getCompatibilityId = (segment) => {
    return +segment.match('mod#id:[0-9]*')[0]?.split(':')[1];
  };

  const modifiers = getModifiers();
  const previousAnswer = modifiers.find((modifier) => modifier.id === props.steps[stepNumber - 1]?.answer);

  const isCompatibleWithPreviousAnswer = (option) => {
    if (stepNumber === 0) return true;
    return (
      option?.compatibleWith?.includes(getCompatibilityId(previousAnswer?.modifierUriSegment)) &&
      previousAnswer?.compatibleWith?.includes(getCompatibilityId(option?.modifierUriSegment))
    );
  };

  useEffect(() => {
    window._env_.REACT_APP_DISABLED_SHOPS?.split(',')?.find((id) => id == props?.shopId)
      ? setDisabled(true)
      : setDisabled(false);
  }, [props?.shopId]);

  return (
    <>
      <FormControl variant='outlined' className={classes.formControl} disabled={disabled}>
        <InputLabel className={classes.inputLabel} id='demo-simple-select-outlined-label'>
          {disabled
            ? 'VÁSÁRLÁS LETILTVA!'
            : props.step.name === 'Terméktípus'
            ? 'Válassz terméktípust'
            : 'Válassz terméket'}
        </InputLabel>
        <Select
          className={classes.inputControl}
          labelId='demo-simple-select-outlined-label'
          id='demo-simple-select-outlined'
          value={answer}
          onChange={(e) => {
            handleAnswer(e.target.value);
            props.next();
          }}
          label={props.step.name}>
          {props.step?.options?.map((option, idx) => {
            return (
              isCompatibleWithPreviousAnswer(option) && (
                <MenuItem value={option.id} key={idx}>
                  {`${option.name} ${
                    option.unitPrice.grossAmount === 0 ? '' : ' - ' + option.unitPrice.grossAmount.toFixed(0) + 'HUF'
                  }`}
                </MenuItem>
              )
            );
          })}
        </Select>
        <div className={classes.actionsContainer}>
          <div>
            <Button onClick={stepNumber === 0 ? props.toggle : props.prev} className={classes.button}>
              Vissza
            </Button>
            <Button
              disabled={
                props.step.optional ? false : answer === null || answer === '' || answer === undefined ? true : false
              }
              variant='contained'
              onClick={props.next}
              className={classes.nextButton}>
              Tovább
            </Button>
          </div>
        </div>
      </FormControl>
    </>
  );
};

export default SelectStep;
