import styled from 'styled-components';
import { IoMdHeartEmpty } from 'react-icons/io';
import { IoMdHeart } from 'react-icons/io';
import { forwardRef } from 'react';
import { makeStyles, withTheme } from '@material-ui/core/styles';
import SIZES from 'constants/sizes';
import Slide from '@material-ui/core/Slide';

export const DialogContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 80%;

  @media (min-width: ${SIZES.MD}px) {
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    max-width: 1000px;
  }
`;

export const Title = styled.h1`
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  color: ${(props) => props.theme.palette.primary.backgroundPrimary};
  margin: 1rem 1rem 0.5rem 1rem;
  text-transform: uppercase;
  font-family: ${(props) => props.theme.typography.main}, Sans-serif, Arial;
`;

export const ProductName = styled.h1`
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
  color: ${(props) => props.theme.palette.primary.backgroundPrimary};
  margin: 1rem 1rem 0.5rem 1rem;
  text-transform: uppercase;
  font-family: ${(props) => props.theme.typography.main}, Sans-serif, Arial;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      width: '100%',
      maxWidth: '1000px',
      padding: '1rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      zIndex: '20',
      background: theme.palette.primary.backgroundSecondary,
      [theme.breakpoints.up('md')]: {
        padding: '2rem'
      }
    }
  },
  dialogContent: {
    padding: 0,
    [theme.breakpoints.up('md')]: {
      padding: '2rem'
    }
  },
  skeleton: {
    borderRadius: '1rem',
    height: '100%',
    minHeight: '300px'
  },
  dialogContext: {
    marginTop: '1rem',
    color: theme.palette.primary.backgroundPrimary,
    fontWeight: 'bold',
    textTransform: 'uppercase !important',
    fontFamily: theme.typography.secondary,
    width: '100%'
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
    alignItems: 'center'
  },

  fileName: {
    marginTop: '1rem',
    color: theme.palette.primary.backgroundPrimary,
    fontFamily: theme.typography.secondary,
    textAlign: 'center',
    width: '100%'
  },

  stepper: {
    width: '100%',
    minWidth: '300px',
    padding: 0,
    margin: 0,
    color: theme.palette.primary.backgroundPrimary,
    background: theme.palette.primary.backgroundSecondary
  },
  basketIcon: {
    position: 'absolute',
    zIndex: '70',
    top: '0.5rem',
    left: '0.5rem',
    fontSize: '1.5rem',
    color: theme.palette.primary.backgroundPrimary
  },
  stepLabel: {
    color: theme.palette.primary.backgroundPrimary
  },
  button: {
    color: theme.palette.primary.backgroundPrimary
  },
  toCartBtn: {
    background: theme.palette.primary.backgroundPrimary,
    color: theme.palette.primary.textSecondary,
    '&:hover': {
      color: 'black',
      background: theme.palette.primary.textPrimary
    }
  },
  payButton: {
    color: theme.palette.primary.textPrimary,
    background: theme.palette.primary.backgroundPrimary,
    '&:hover': {
      color: 'black',
      background: theme.palette.primary.primaryGrey
    }
  },
  totalPrice: {
    marginBottom: '0.5rem',
    color: theme.palette.primary.backgroundPrimary
  },
  spinner: {
    color: `${theme.palette.primary.textPrimary} !important`
  }
}));

export const ButtonsWrapper = styled.div`
  align-self: center;
  height: 1rem;
`;

export const ProductCard = styled.div`
  cursor: pointer;
  background-color: var(--color-light) !important;
  border-radius: 1rem;
  z-index: 0;
  position: relative;
  overflow: hidden;
`; // TODO fix this it doesnt work

export const Image = styled.img`
  border-radius: 1rem;
  transition: 0.3s all;
  width: 100%;
  &: hover {
    opacity: 80%;
  }
`;

export const MediaSection = styled.div``;

export const DialogImage = styled.img`
  margin: auto;
  height: 100%;
  max-width: 100%;
`;

export const CartIconWrapper = styled.div`
  background-color: ${(props) => props.theme.palette.primary.backgroundSecondary};
  position: absolute;
  border-radius: 0 0 0 0 4px;
  clip-path: polygon(0 0, 100% 0, 0 100%);
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: 20;
  width: 65px;
  height: 65px;
`;

export const HeartHolder = styled.div`
  position: absolute;
  right: 0;
  bottom: 0.25rem !important;
  zindex: 1;
  height: 45px;
  width: 45px;
  // width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  border-radius: 2rem 0 1rem 0;
  // background: ${(props) => props.theme.palette.primary.backgroundSecondary};
  boxshadow: inset 0 6px 15px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s all;
`;

const StyledFilledHearth = withTheme(styled(IoMdHeart)`
  fill: white;
  height: 40px;
  width: 40px;
`);

const StyledHearth = withTheme(styled(IoMdHeartEmpty)`
  fill: ${(props) => props.theme.palette.primary.textSecondary};
  height: 40px;
  width: 40px;
`);

export const HeartIcon = ({ isLiked, toggleLike, hover }) => {
  return (
    <HeartHolder style={{ opacity: hover || isLiked ? 1 : 0 }} onClick={(e) => toggleLike(e)}>
      {isLiked ? <StyledFilledHearth /> : <StyledHearth />}
    </HeartHolder>
  );
};
