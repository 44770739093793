import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const Title = styled.h3`
  margin: 1rem;
  font-size: 14px;
  font-family: Roboto;
  @media (min-width: 768px) {
    font-size: 16px;
  }
`;

export const Hashtag = styled.span`
  color: ${({ theme }) => theme.palette.primary.backgroundSecondary};
`;

export const useStyles = makeStyles((theme) => ({
  details: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 1rem 1rem 1rem'
  },
  container: {
    borderRadius: '1.25rem',
    border: 'unset',
    boxShadow: 'unset',
    borderTopLeftRadius: '1.25rem !important',
    borderTopRightRadius: '1.25rem !important',
    borderBottomLeftRadius: '1.25rem !important',
    borderBottomRightRadius: '1.25rem !important',
    position: 'unset'
  }
}));
