import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';

export const IntroSectionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  @media (min-width: 992px) {
    padding: 5rem 0;
  }
`;

export const IntroSectionDiv = styled.div`
  width: 100%;
  max-width: 1024px;
  display: flex;
  flex-direction: column;
  margin-top: 0;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;

export const PortraitWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3;
`;

export const PortraitInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 5px solid ${(props) => props.theme.palette.primary.backgroundPrimary};
  background-color: ${(props) => props.theme.palette.primary.backgroundContent};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.22), 0 6px 20px 0 rgba(0, 0, 0, 0.22);
  border-radius: 50%;
  overflow: hidden;
  width: 200px;
  height: 200px;
  @media (min-width: 768px) {
    width: 255px;
    height: 255px;
  }
`;

export const PortraitImage = styled.img`
  width: 60vw;
  max-width: 200px;
  margin: 0 auto;

  @media (min-width: 768px) {
    max-width: 250px;
  }
`;

export const BlendeImageWrapper = styled.div`
  display: hidden;
  position: absolute;
  @media (min-width: 1250px) {
    display: block;
    height: 980px;
    width: 980px;
    bottom: 0;
    right: 0rem;
    position: absolute;
  }
`;
export const BlendeImageWrapper2 = styled.div`
  display: hidden;
  position: absolute;
  @media (min-width: 1250px) {
    display: block;
    height: 50%;
    width: 250px;
    top: 0;
    left: 0;
    margin-bottom: 1.5rem;
  }
`;

// export const BlendeImageWrapper = styled.div`
//   height: 190%;
//   width: 700px;
//   position: absolute;
//   right: 0rem;
//   z-index: 1;
//   margin-bottom: 1.5rem;
// `;

export const Name = styled.div`
  font-size: 35px;
  font-weight: 600;
  text-align: center;
  color: ${(props) => props.theme.palette.primary.textSecondary};
  margin: 1rem;
  text-transform: uppercase;
  line-height: 90%;
  margin-top: 2rem;
`;

export const Description = styled.div`
  font-size: 1rem;
  color: ${(props) => props.theme.palette.primary.textSecondary};
  max-width: 900px;
  line-height: 150%;
  padding-bottom: 1.5rem;
  z-index: 2;
  margin: 2rem;
  @media (min-width: 992px) {
    margin: 5rem 0 0 0;
  }
`;

export const HeroSection = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 0 2rem 0;
  flex-direction: column;
  @media (min-width: 768px) {
    margin: 4rem 0 4rem 0;
    gap: 3rem;
    flex-direction: row;
  }
  @media (min-width: 992px) {
    margin: 1rem 0 1rem 0;
  }
  @media (min-width: 1200px) {
    gap: 8rem;
  }
`;

export const useStyles = makeStyles((theme) => ({
  introSection: {
    backgroundColor: theme.palette.primary.backgroundSecondary
  },
  introText: {
    color: theme.palette.primary.backgroundPrimary,
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    '& h1': {
      lineHeight: '2.5rem',
      fontSize: '28px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '35px',
        lineHeight: '3rem'
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '45px',
        lineHeight: '4rem'
      }
    },
    '& p': {
      fontSize: '18px',
      lineHeight: '1.75rem'
    }
  },
  introTitle: {
    color: theme.palette.primary.backgroundPrimary,
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    fontSize: '28px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '35px'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '45px'
    }
  },
  underline: {
    textDecorationColor: theme.palette.primary.dark,
    textUnderlineOffset: '5px'
  }
}));
